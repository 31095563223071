"use strict";
import { gsap, Power4, Linear } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TweenMax, ScrollToPlugin } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
document.addEventListener("DOMContentLoaded", function () {
    var ring = document.getElementById("rotate-ring");
    var cta = document.getElementById("cta");
    var foot = document.getElementById("foot");
    TweenMax.to(ring, 4, { rotation: "-360", ease: Linear.easeNone, repeat: -1 });
    if (cta) {
        ScrollTrigger.create({
            trigger: cta,
            pin: true,
            endTrigger: "body",
            start: "top 20px",
            end: "bottom bottom",
        });
        cta.addEventListener("click", function (e) {
            e.preventDefault;
            var footpos = document.getElementById("foot").offsetTop;
            gsap.to(window, { duration: 2, scrollTo: "#foot" });
        });
    }
    document.querySelectorAll(".portfolio-item").forEach(function (item) {
        item.addEventListener("click", function () {
            item.classList.toggle("show-info");
        });
        var duration = 1.5;
        var anim = gsap.from(item, {
            y: 100,
            opacity: 0,
            duration: duration,
            ease: Power4.easeOut,
            paused: true,
        });
        ScrollTrigger.create({
            trigger: item,
            start: "top bottom",
            onEnter: function () { return anim.play(); },
        });
        ScrollTrigger.create({
            trigger: item,
            start: "-50px bottom",
            onLeaveBack: function () { return anim.pause(0); },
        });
        var adjustRightSideAnimations = function () {
            var rightSide = item.getBoundingClientRect().left >= window.innerWidth / 2;
            if (rightSide) {
                anim.duration(duration + 1);
            }
            else {
                anim.duration(duration);
            }
        };
        adjustRightSideAnimations();
        window.addEventListener("resize", adjustRightSideAnimations);
        var rect = item.getBoundingClientRect();
        var isInsideViewport = (rect.top >= 0 || rect.bottom >= 0) &&
            (rect.top <= window.innerHeight || rect.bottom <= window.innerHeight);
        if (isInsideViewport) {
            anim.seek(anim.duration());
        }
        var video = item.querySelector("video");
        if (video) {
            var loadVideo = function () {
                if (video.dataset.mobileUrl &&
                    document.documentElement.clientWidth <=
                        parseInt(video.dataset.mobileBreakpoint)) {
                    video.src = video.dataset.mobileUrl;
                }
                else {
                    video.src = video.dataset.src;
                }
            };
            ScrollTrigger.create({
                trigger: video,
                start: "-300px bottom",
                onEnter: loadVideo,
                once: true,
            });
            ScrollTrigger.create({
                trigger: video,
                start: "top bottom",
                end: "50% top",
                onEnter: function () {
                    video.play();
                },
                onEnterBack: function () {
                    video.play();
                },
                onLeave: function () {
                    if (video.currentTime > 0 &&
                        !video.paused &&
                        !video.ended &&
                        video.readyState > 2) {
                        video.pause();
                    }
                },
                onLeaveBack: function () {
                    video.pause();
                },
            });
        }
        var picture = item.querySelector("picture");
        if (picture) {
            var loadImage = function () {
                var sources = picture.querySelectorAll("source");
                sources.forEach(function (source) {
                    if (source.srcset == "") {
                        source.srcset = source.dataset.srcset;
                    }
                });
            };
            ScrollTrigger.create({
                trigger: picture,
                start: "-300px bottom",
                onEnter: loadImage,
                once: true,
            });
        }
    });
});
